import BannerEnum from '../enumerators/banner.enum'
import FrontPageEnum from '../enumerators/front-page.enum'

export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            if (!IRISCollectionCustomer.isEcomodeActive) {
                import(
                    '@scripts/components/slider-home.component' /* webpackChunkName: "scripts/slider-home.component" */
                ).then(({ default: SliderHomeComponent }) => {
                    new SliderHomeComponent()
                })

                import(
                    '@scripts/services/animation.service' /* webpackChunkName: "scripts/animation.service" */
                ).then(({ default: AnimationService }) => {
                    new AnimationService()
                })

                if (window.matchMedia('(min-width: 999px)').matches) {
                    this.strateKeywords()
                }

                if (
                    $('[data-element="strate-video"]').length > 0 &&
                    window.matchMedia('(min-width: 650px)').matches
                ) {
                    import(
                        '@scripts/components/video-loading.component' /* webpackChunkName: "scripts/video-loading.component" */
                    ).then(({ default: VideoLoadingComponent }) => {
                        new VideoLoadingComponent()
                    })
                }
            }
        }
    }

    strateKeywords() {
        if (
            $(FrontPageEnum.CONTAINER_KEYWORDS).length > 0 &&
            !$(FrontPageEnum.CONTAINER_KEYWORDS).is(':hidden')
        ) {
            const trigger = FrontPageEnum.TRIGGER_CARDS
            const thumb = FrontPageEnum.THUMB_CARDS
            let animationReady = true
            let timeoutFn = null

            $(trigger).first().addClass('is-active')

            $(document).on('mouseover', trigger, function () {
                if (animationReady) {
                    clearTimeout(timeoutFn)
                    timeoutFn = setTimeout(() => {
                        animationReady = false
                        $(thumb).removeClass('is-active')
                        $(trigger).removeClass('is-active')
                        $(`[data-discover-thumb="${$(this).data('discover-trigger')}"]`).addClass(
                            'is-active',
                        )
                        $(`[data-discover-trigger="${$(this).data('discover-trigger')}"]`).addClass(
                            'is-active',
                        )
                        animationReady = true
                    }, 250)
                }
            })
        }
    }
}
